import React from "react";

import "../assets/css/main.css";

import Section1 from "./Section1";
import Section2 from "./Section2";
import Section3 from "./Section3";
import Section5 from "./Section5";
import CleanForm from "./CleanForm";
import Footer from "./Footer";
import Esg from "./Esg";
import Recycle from "./Recylce";
import Service from "./Service";
import Review from "./Review";
import Quotation from "./Quotation";
import Section4 from "./Section4";

export default function Main() {
  return (
    <div style={{ position: "absolute", width: "100%", top: 0 }}>
      <Section1 />
      <Service />
      <Review />
      <Section2 />
      <Section4 />
      <Section3 />
      <Section5 />
      {/* <Quotation /> */}
      <CleanForm />
      <Esg />
      <Recycle />
      <Footer />
    </div>
  );
}
