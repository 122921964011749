import React from "react";
import "../assets/css/certificate.css";
import cert5 from "../assets/images/cert5.jpg";

export default function Section1() {
  return (
    <div id="clean-section-1">
      <div className="clean-section-in">
        <div className="title_box">
          <div className="title">
            <h2>입주 청소부터 건물 정기 청소까지</h2>
            <h1 className="title_p" data-aos="fade">
              먼지 없이, 걱정 없이
            </h1>
            <h3>사장님은 사업에만 집중하세요!</h3>
            <h3>믿을 수 있는 헬퍼잇 청소 서비스</h3>
            <button onClick={() => window.open("#clean-form", "_self")}>
              신청하기
            </button>
          </div>
        </div>
        <div className="frame" style={{ height: "500px" }}>
          <img src={cert5} className="certificate content" alt="신분증" />
        </div>
      </div>
    </div>
  );
}
