import React from "react";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import clean20 from "../assets/images/clean/clean20.jpeg";
import clean21 from "../assets/images/clean/clean21.jpeg";
import clean22 from "../assets/images/clean/clean22.jpeg";
import clean23 from "../assets/images/clean/clean23.jpeg";

export default function Section4() {
  return (
    <div id="clean-section-4">
      <div className="clean-section-in">
        <div className="title_box">
          <div className="title" data-aos="fade-up" data-aos-offset="100">
            <h1 className="title_p" data-aos="fade-up" data-aos-offset="100">
              냄새나고 하기싫은
              <br />
              화장실 청소
              <br />
              <span className="color_lime">
                헬퍼잇클린으로
                <br />
                쉽고 빠른 화장실 청소
              </span>
            </h1>
            <h2>헬퍼잇클린 프리미엄 청소 서비스</h2>
          </div>
          <div className="img_box">
            <ul>
              <li data-aos="fade" data-aos-offset="300">
                <img src={clean20} alt="현장사진1" />
              </li>
              <li data-aos="fade" data-aos-offset="300">
                <img src={clean21} alt="현장사진2" />
              </li>
              <li data-aos="fade" data-aos-offset="200">
                <img src={clean22} alt="현장사진3" />
              </li>
              <li data-aos="fade" data-aos-offset="200">
                <img src={clean23} alt="현장사진4" />
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
